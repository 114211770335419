import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SecurityContext, SimpleChanges, ViewChild } from '@angular/core';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { TranscriptModel } from '@admin/models/evaluation/transcript';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { WaveformAudioService } from '@admin/services/evaluation/waveformAudio.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from '@core/layout/services/layout.service';

@Component({
  selector: 'app-transcript',
  templateUrl: './transcript.component.html',
  styleUrls: ['./transcript.component.scss']
})
export class TranscriptComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() transcriptID: string;
  searchValue = '';
  isShow = false;
  listOfData: TranscriptModel[] = [];
  listOfDisplayData: TranscriptModel[] = [...this.listOfData];
  textKeyWord: string;
  timeKeyword: any;
  highlightedIndices: number[] = [];
  @ViewChild('virtualTable', { static: false })
  nzTableComponent: NzTableComponent<any>;
  private destroy$ = new Subject();

  transcriptByTime = {};
  isHighlightOfAudio: number = -1;

  formGroup: FormGroup;
  currentSearchIndex = 0;
  transcriptResults: any[] = [];

  constructor(
    public formatTimeService: FormatTimeService,
    private evaluationService: EvaluationService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private waveformAudioService: WaveformAudioService,
    public layoutService: LayoutService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['transcriptID'].currentValue) {
      this.transcriptID = changes['transcriptID'].currentValue;
      this.evaluationService.getTranscript(this.transcriptID).subscribe({
        next: (result: any) => {
          this.transcriptByTime = {};
          this.listOfDisplayData = result.data.content.map((item: any, index: number) => {
            const timeStart = item.start;
            const timeEnd = item.end;
            if (timeStart >= 0 && timeEnd >= 0 && timeEnd > timeStart) {
              for (let time = timeStart; time <= timeEnd; time += 0.01) {
                this.transcriptByTime[time.toFixed(2)] = index;
              }
            }

            return new TranscriptModel(item);
          });
          this.evaluationService.isSuccessAPI(true);
        },
        error: err => {
          this.listOfDisplayData = [];
          this.evaluationService.isSuccessAPI(true);
        }
      });
    }
  }

  scrollToAudio(time: number): void {
    const index = this.transcriptByTime[time.toFixed(2)];
    if (index >= 0 && index !== this.isHighlightOfAudio) {
      const behavior = 'smooth';
      this.isHighlightOfAudio = index;
      this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index, behavior);
    }
  }

  scrollToTime(time: any): void {
    const index = this.listOfDisplayData.findIndex((item: any, index: number) => {
      this.timeKeyword = item.timeStart;
      return item.timeStart === time && time >= 0;
    });
    if (index !== -1) {
      this.isHighlightOfAudio = index;
      this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index);
    }
  }

  trackByIndex(_: number, data: TranscriptModel): number {
    return data?.['id'];
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.evaluationService.behaviorValueKeywords.subscribe({
      next: res => {
        if (res) {
          this.textKeyWord = res.keyword;
          this.scrollToTime(res.startTime);
        }
      }
    });
    this.waveformAudioService.getCurrentTime().subscribe({
      next: res => {
        this.scrollToAudio(res);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.nzTableComponent && this.nzTableComponent.cdkVirtualScrollViewport) {
      this.nzTableComponent.cdkVirtualScrollViewport.scrolledIndexChange.pipe(takeUntil(this.destroy$)).subscribe((data: number) => {});
    }
  }

  hasTextOrIntentSearch(searchContent: string, displayData: any): boolean {
    const regex = RegExp(searchContent, 'i');
    const hasText = !!regex.exec(displayData.text);
    if (!displayData.intents.length) {
      return hasText;
    }
    const hasIntent = displayData.intents.filter(i => regex.exec(i.value)).length;
    return hasText || hasIntent;
  }

  searchTranscript(searchContent: string) {
    if (!searchContent) {
      this.transcriptResults = [];
      this.currentSearchIndex = 0;
      return;
    }
    this.transcriptResults = this.listOfDisplayData.filter(d => this.hasTextOrIntentSearch(searchContent, d));
    this.currentSearchIndex = 0;
    if (!this.transcriptResults[this.currentSearchIndex]) {
      return;
    }
    this.scrollToTime(this.transcriptResults[this.currentSearchIndex].timeStart);
  }

  getOtherResult(isNext: boolean) {
    if (this.transcriptResults.length === 1) {
      return;
    }
    this.currentSearchIndex = this.getCurrentIndex(isNext);
    this.scrollToTime(this.transcriptResults[this.currentSearchIndex].timeStart);
  }

  getCurrentIndex(isNext: boolean): number {
    if (isNext && this.currentSearchIndex + 1 === this.transcriptResults.length) {
      return 0;
    }
    if (isNext) {
      return this.currentSearchIndex + 1;
    }
    if (!isNext && this.currentSearchIndex === 0) {
      return this.transcriptResults.length - 1;
    }
    return this.currentSearchIndex - 1;
  }

  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      searchContent: ['']
    });
    this.formGroup.controls['searchContent'].valueChanges.pipe(debounceTime(500)).subscribe(x => {
      this.searchTranscript(this.formGroup.value.searchContent);
    });
  }

  highlightedContent(content: string, searchTerm: string, time?: number): string {
    if (!content || !searchTerm) {
      return this.sanitizer.sanitize(SecurityContext.HTML, content) || '';
    }
    const regex = new RegExp(searchTerm, 'gi');
    if (time === this.timeKeyword) {
      const highlightedContent = content.replace(regex, `<span class="color-red">${searchTerm}</span>`);
      return this.sanitizer.sanitize(SecurityContext.HTML, highlightedContent) || '';
    } else {
      return this.sanitizer.sanitize(SecurityContext.HTML, content) || '';
    }
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    this.isShow = false;
    if (this.searchValue) {
      this.listOfDisplayData.forEach((data, index) => {
        const regex = new RegExp(this.searchValue, 'gi');
        let match = regex.exec(data.text);
        while (match) {
          this.highlightedIndices.push(index);
          match = regex.exec(data.text);
        }
      });
    }
  }

  // scrollToNext() {
  //   if (this.highlightedIndices.length > 0) {
  //     const nextIndex = this.highlightedIndices.shift();
  //     const row = document.getElementById(`row-${nextIndex}`);
  //     if (row) {
  //       row.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //     }
  //   }
  // }

  jumpAudio(event: any) {
    this.evaluationService.jumpAudio(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next(0);
    this.destroy$.complete();
    this.evaluationService.jumpAudio(null);
  }
}
