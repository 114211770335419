import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { TicketDetailModel } from '@admin/models/evaluation/ticketDetail';
import { NgxSpinnerService } from 'ngx-spinner';
import { LayoutService } from '@core/layout/services/layout.service';
import { take, timer } from 'rxjs';
import { PermissionsService } from '@shared/service/permission.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit, OnDestroy {
  @ViewChild('childWaveform', { read: ViewContainerRef }) childWaveformContainer: ViewContainerRef;
  @ViewChild('childTranscript', { read: ViewContainerRef }) childTranscriptContainer: ViewContainerRef;

  RIGHTS_CODE = RIGHTS_CODE;
  idTicketDetail: string;
  ticketDetail: TicketDetailModel;
  transcript: string;
  audioInfo: any;
  totalKpi: any;
  isChangeStatus = 'close';
  tabs = [
    {
      name: 'evaluation.ticket_details.kpi_est.title_1',
      icon: 'read',
      component: 'ESTIMATION_KPI'
    },
    {
      name: 'evaluation.ticket_details.kpi_est.title_2',
      icon: 'history',
      component: 'HISTORY'
    }
  ];
  tabsTicketInfo = [
    {
      name: 'evaluation.ticket_details.call_info.title',
      icon: 'read',
      component: 'CALL_INFO'
    },
    {
      name: 'evaluation.ticket_details.ticket_info.title',
      icon: 'history',
      component: 'TICKET_INFO'
    }
  ];

  constructor(
    public layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private evaluationService: EvaluationService,
    private spinner: NgxSpinnerService,
    public permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    timer(0)
      .pipe(take(1))
      .subscribe(() => {
        this.layoutService.toggleMenu();
      });

    this.activatedRoute.params.subscribe(params => {
      this.idTicketDetail = params['id'];
      this.handleGetTicketDetail(this.idTicketDetail);
    });

    this.evaluationService.currentSuccess.subscribe(data => {
      if (data) {
        this.spinner.hide();
      } else {
        this.spinner.show();
      }
    });

    this.evaluationService.dataTransfer.subscribe(data => {
      if (data) this.ticketDetail = { ...this.ticketDetail, metadata: data };
    });
  }

  handleGetTicketDetail(id) {
    this.spinner.show();
    this.evaluationService.getTicketDetail(id).subscribe({
      next: (result: any) => {
        this.ticketDetail = new TicketDetailModel(result.data);
        this.totalKpi = {
          totalScoreAuto: this.ticketDetail.totalScoreAuto,
          totalScoreByQc: this.ticketDetail.totalScoreByQc
        };
        this.transcript = this.ticketDetail.transcriptId;
        this.audioInfo = {
          audioId: this.ticketDetail.audioId,
          callId: this.ticketDetail.callId,
          statusTicket: this.ticketDetail.statusTicket,
          fileUrl: this.ticketDetail.fileUrl,
          fileName: this.ticketDetail.fileName
        };
        this.spinner.hide();
      },
      error: (err: {}) => {
        this.spinner.hide();
        window.location.href = '/admin/evaluation/ticket-list';
      }
    });
  }

  handleIsChangeStatus(isShow: string) {
    switch (isShow) {
      case 'open':
        this.isChangeStatus = 'open';
        break;
      case 'close':
        this.isChangeStatus = 'close';
        break;
      case 'change':
        this.isChangeStatus = 'change';
        break;
      default:
        this.isChangeStatus = 'close';
    }
  }

  outputChangeStatusQC(event: string) {
    if (event) {
      this.handleIsChangeStatus('close');
      this.handleGetTicketDetail(this.idTicketDetail);
    }
  }

  destroyComponents() {
    this.childWaveformContainer.clear();
    this.childTranscriptContainer.clear();
  }

  ngOnDestroy() {
    this.destroyComponents();
  }
}
