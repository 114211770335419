import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from '@shared/service/base.service';
import { PermissionsService } from '@shared/service/permission.service';
import { Router } from '@angular/router';

import { ENDPOINT } from '@shared/config/endpoint';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  router = inject(Router);

  permissionsService = inject(PermissionsService);

  private _isExpandTranscript = signal(false);

  private showMenu = new BehaviorSubject(this.router.url !== '/admin/org-owns' && !!this.permissionsService.rights);
  IsShow = this.showMenu.asObservable();

  constructor(private baseService: BaseService) {}

  get isExpandTranscript() {
    return this._isExpandTranscript();
  }

  set isExpandTranscript(value: boolean) {
    this._isExpandTranscript.set(value);
  }

  toggleMenu(value?: boolean) {
    if (value === undefined) {
      this.showMenu.next(!this.showMenu.getValue());
      return;
    }
    this.showMenu.next(value);
  }

  getListOrganizations() {
    return this.baseService.getData(`${ENDPOINT.auth.organizations}`);
  }
}
